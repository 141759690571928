import React from 'react'
import Title from "../title/title"
import Block from "../block/block"
import Paragraph from "../paragraph/paragraph"
import './blockCategory.scss'

const BlockCategory = ({ title, text, icon, anim, delay, style, textColor }) => {
    return(
        <Block anim={anim} delay={delay} style={`pr-3 block-category ${style || ''}`}>
            <img src={icon} style={{height:'72px', marginBottom: '14px'}}/>
            <Title title={title} style={`${textColor || ''}`}/>
            <Paragraph text={text} style={`${textColor || ''}`}/>
        </Block>
    )
}

export default BlockCategory